import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";


const reducer = combineReducers({
  appStateSlice
})

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;