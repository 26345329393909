import React from 'react';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { BounceAnimation, MoveFadeInAnimation } from '../../hooks/useAnimations';

type Props = {
    spacingClasses: string;
    name: string;
    content: string;
    imagePath: string;
    imageWidth: number;
}
const Article = ({spacingClasses, name, content, imagePath, imageWidth}:Props) => {
    return (
        <>
        <Grid container className={`lg:w-full justify-between ${spacingClasses}`}>
            <Grid item xs={12} md={7}>
                <MoveFadeInAnimation direction="left" distance="sm" amount={0.5}>
                    <Item className="backdrop-blur-xl bg-white bg-opacity-10 rounded-2xl p-10 text-center">
                        <Typography className="mb-3 font-bold" variant="h5">{ name }</Typography>
                        <Typography>
                            { content }
                        </Typography>
                    </Item>
                </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={5} className={`p-0 justify-center flex flex-col mt-10 md:mt-0`}>
            <BounceAnimation delay={0}>
                <CardMedia
                    className="mx-auto"
                    component="img"
                    image={`${imagePath}`}
                    style={{ width: `${imageWidth}%`, alignSelf: 'end', marginTop: '2%' }}
                        />
            </BounceAnimation>
            </Grid>
        </Grid>
        </>
    );
}

export default Article;