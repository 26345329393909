
const colorConfigs = {
  sidebar: {
    bg: "#1c172d",
    color: "#eeeeee",
    hover: "#a050fe",
    activeBg: "#1e253a"
  },
  topbar: {
    bg: "transparent",
    color: "#1D1D1B"
  },
  mainBg: "#1D1D1B"
};

export default colorConfigs;