import Typography from "@mui/material/Typography";
import React from "react";


import Hero from "../../components/elements/Hero";
import { Container } from "@mui/material";

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

// import TeamCard from "./elements/TeamCard";

import HeroModal from "../../components/elements/Modals/HeroModal";

import useMeta from "../../components/useMeta";

type Props = any;

const Benefits = (props: Props) => {
  return (
    useMeta({
      title: 'ActOnIQ',
      description: '/',
      keywords: '',
    }),
    <div></div>
  )
};

export default Benefits;