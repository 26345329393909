import React from "react";
import Typography from "@mui/material/Typography";

import { CardMedia, Container } from "@mui/material";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Item from '@mui/material/Grid';

import Articles from "../../components/elements/Articles";
import Feature from "../../components/elements/Feature";

import useMeta from "../../components/useMeta";
import InvestorForm from "../../components/elements/Forms/InvestorForm";

import Join from "../join/Join";

import { BounceAnimation, MoveFadeInAnimation } from "../../hooks/useAnimations";

type Props = any;

const HomePage = (props: Props) => {
  return (
    useMeta({
      title: 'ActOnIQ: Revolutionizing Project Management with AI Integration',
      description: 'Discover ActOnIQ, the future of project management. Our platform seamlessly integrates tools like Jira, Asana, and Monday with advanced AI capabilities, offering unprecedented efficiency and data-driven insights. Revolutionize how your business operates and stays ahead in a dynamic market with ActOnIQ.',
      keywords: 'ActOnIQ, AI Project Management, Business Efficiency, Integration Platform, AI Insights, Jira, Asana, Monday, Workflow Automation, Data-Driven Decision Making, ICT Solutions, Business Intelligence, Artificial Intelligence, Project Management Software.',
    }),
    <div>
        <Container className="">
          <Grid container direction="column" justifyContent="center" alignItems="center" className="pt-30 pb-10 md-pt-50 md-pb-10 sm-pt-40 sm-pb-10">
            <Grid item xs={12} md={6} className=''>
              <BounceAnimation delay={0} extraclasses="flex flex-col">
                <CardMedia
                  component="img"
                  alt="Background"
                  image="/svg/actoniq.svg"
                  style={{ width: '80%', height: 'auto', justifyContent: 'center', alignSelf: 'center' }}
                />
              </BounceAnimation>
            </Grid>
            <Grid item xs={12} md={6} className='flex flex-col mt-10 text-center'>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography className="font-normal text-2xl">
                  ’Revolutionize Your Business Efficiency with ActOnIQ: <br /> The Future of Integrated Project Management’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={6} className='flex flex-col mt-10'>
              <BounceAnimation delay={0}>
                <Button className="rounded-full btn-meet text-white font-bold px-8 py-3" variant="contained">
                  <a href="/contact" target="blank">Contact us</a>
                </Button>
              </BounceAnimation>
            </Grid>
        </Grid>
        </Container>

        <Container className="" id="about"></Container>
        
        <Container className="spacing-sections">
          <Grid item xs={12} md={6} className='flex flex-col text-center'>
            <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <Typography>
                Stay Ahead with AI-Driven Projections: Empower your business to be proactive with ActOniQ’s advanced AI insights and forecasts, ensuring you’re always one step ahead in a fast-paced market.
              </Typography>
            </MoveFadeInAnimation>
          </Grid>
          <Feature spacingClasses="mt-10" name="Revolutionizing Project Management" content="Introducing ActOniQ: Step into a new era of efficiency with ActOniQ, the groundbreaking platform transforming how businesses manage projects. Our innovative solution integrates popular tools like Jira, Asana, and Monday into a single, powerful dashboard, streamlining your workflow like never before." imagePath="/images/dashboard.png" imageWidth={70}/>
          <Feature spacingClasses="mt-16" name="Unprecedented Integration" content="Seamless, Unified Access: Imagine managing all your projects, tasks, and deadlines across various platforms from one central location. ActOniQ makes this possible, merging the strengths of different management tools into one intuitive interface, enhancing both versatility and specificity for the modern business landscape." imagePath="/svg/all-brands.svg" imageWidth={60}/>
          <Feature spacingClasses="mt-16" name="Elevate Your Operational Efficiency" content="Insightful, Data-Driven Decisions: ActOniQ provides comprehensive insights by consolidating data from multiple sources, enabling smarter, faster decision-making. Our platform significantly reduces workload, automating routine tasks and freeing your team to focus on critical aspects of business." imagePath="/svg/powered-by-ai.svg" imageWidth={20}/>
        </Container>

        <Container className="" id="benefits"></Container>

        <Container className="spacing-sections">
          <Grid container spacing={2} className="lg:w-full lg:justify-between">
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation delay={0}>
                <div className="h-40 flex">
                  <img className="h-15 m-auto w-18" src="../../images/notification.png" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="h5">
                  <strong>’Your Work, Streamlined:</strong> AI-Driven Insights & Projections at Your Fingertips’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation delay={0}>
                <div className="h-40 flex">
                  <img className="h-15 m-auto" src="../../images/business.png" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="h5">
                  <strong>’Smart Business Decisions:</strong> Leveraging AI for Accurate Forecasting and Optimized Performance’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation delay={0}>
                <div className="h-40 flex">
                  <img className="h-15 m-auto" src="../../images/loader.png" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="h5">
                  <strong>’Stay Ahead:</strong> Agile, Informed, and Always a Step Ahead in Your Industry’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>

            <Container className="" id="roadmap"></Container>
            
            <Grid item xs={12} md={12} className="text-center mt-12">
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography className="font-normal" variant="h5">
                  Provide us with your facts to get in touch.
                </Typography>
              </MoveFadeInAnimation>
            </Grid>

            <Grid item xs={12} md={12} className="text-center mt-12">
              <Item className="flex justify-center">
                <BounceAnimation delay={0}>
                  <Join />
                </BounceAnimation>
              </Item>
            </Grid>


            <Grid item xs={12} md={12} className="text-center mt-12">
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography className="font-bold" variant="h5">
                  Our Roadmap and Current Progress
                </Typography>
                <Typography className="font-normal max-w-[80%] mx-auto" variant="subtitle1">
                  Our Journey Begins in ICT: At ActOniQ, our initial focus is set on transforming the ICT sector by addressing its core challenges and enhancing efficiency. Our roadmap is ambitiously laid out to broaden our horizons, aiming to seamlessly integrate a variety of business tools, encompassing areas like accounting, HR, and more. Imagine a world of endless possibilities with us.
                  </Typography>
                  <Typography className="font-normal max-w-[80%] mx-auto mt-5" variant="subtitle1">
                  To give you a real-time glimpse into our ongoing endeavors, we invite you to view our progress on the ActOniQ dashboard. This interactive platform not only showcases what we are currently working on but also reflects the dynamic capabilities of ActOniQ itself. Explore our journey in action.
                </Typography>
              </MoveFadeInAnimation>  
            </Grid>

            
            <Grid item xs={12} md={12} className='flex flex-col mt-10'>
              <BounceAnimation delay={0}>
                <Button className="rounded-full btn-meet text-white font-bold px-8 py-3 w-52 mx-auto" variant="contained">
                  <a href="/roadmap">
                    Navigate to our board
                  </a>
                </Button>
              </BounceAnimation>
            </Grid>
            <CardMedia
              className="mx-auto"
              component="img"
              image="/images/pijl-rechts.png"
              style={{ width: '38%',
              marginRight: '0%',
              marginTop: '-25%' }}
            />
          </Grid>
        </Container>

        <Container className="" id="join"></Container>

        <Container className="spacing-sections">
          <Grid container spacing={2} className="lg:w-full lg:justify-between">
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation direction="right" distance="sm" amount={0.5}>
                <div className="h-40 flex">
                  <img className="h-15 w-12 m-auto" src="../../svg/lamp.svg" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="h5">
                  <strong>’Market Surge:</strong> Project management software market is booming, set to nearly double to $7.63B by 2022.’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation direction="right" distance="sm" amount={0.5}>
                <div className="h-40 flex">
                  <img className="h-15 w-12 m-auto" src="../../svg/ai.svg" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography variant="h5">
                  <strong>’Strategic Edge:</strong> ActOniQ leads with an unmatched all-in-one, AI-enhanced platform, transforming project management’
                </Typography>
              </MoveFadeInAnimation>
            </Grid>
            <Grid item xs={12} md={4} className="text-center">
              <BounceAnimation direction="right" distance="sm" amount={0.5}>
                <div className="h-40 flex">
                  <img className="h-15 w-12 m-auto" src="../../svg/arrow.svg" alt="logo-banner" loading='lazy' />
                </div>
              </BounceAnimation>
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
              <Typography variant="h5">
                <strong>’AI-Powered Innovation:</strong> We set the pace with integrated, intelligent solutions that redefine industry standards for productivity.’
              </Typography>
              </MoveFadeInAnimation>
            </Grid>

            <Grid item xs={12} md={12} className="text-center mt-12">
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography className="font-normal" variant="h5">
                  Provide us with your e-mail address to get in touch.
                </Typography>
              </MoveFadeInAnimation>
            </Grid>

            <Grid item xs={12} md={12} className="text-center mt-12">
              <Item className="flex justify-center">
                <BounceAnimation delay={0}>
                  <InvestorForm />
                </BounceAnimation>
              </Item>
            </Grid>

            <Grid item xs={12} md={12} className="text-center mt-12">
              <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                <Typography className="font-bold" variant="h5">
                  Join our mission
                </Typography>
                <Typography className="font-normal max-w-[80%] mx-auto" variant="subtitle1">
                  <strong>Unprecedented Integration:</strong> As we actively develop our groundbreaking solution, ActOniQ invites forward-thinking investors to join our journey. We’re not just building a product; we’re crafting a scalable, innovative tool that will redefine how businesses manage projects. Our focus now is on expanding our capacity and expertise to ensure the release of a robust, market-leading platform. If you’re interested in being a part of this revolutionary change, we encourage you to explore investment opportunities with us. Schedule a call or meeting to learn more about how ActOniQ is shaping the future of project management and how you can be a part of this exciting venture.
                </Typography>
              </MoveFadeInAnimation>

              <BounceAnimation delay={0}>
                <Grid item xs={12} md={12} className='flex flex-col mt-10'>
                  <Button className="rounded-full btn-meet text-white font-bold px-8 py-3 w-52 mx-auto" variant="contained">
                  <a href="/contact">
                    Get in touch
                  </a>
                  </Button>
                </Grid>
              </BounceAnimation>
              <CardMedia
                className="mx-auto"
                component="img"
                image="/images/pijl-links.png"
                style={{ width: '38%',
                marginLeft: '0%',
                marginTop: '-25%' }}
              />
            </Grid>
          </Grid>
        </Container>

        <Container className="spacing-sections">
          <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
            <Articles />
          </MoveFadeInAnimation>
        </Container>

        <div className="absolute bottom-0 right-0 aspect-square w-full -z-10 overflow-hidden">
        <div className="w-full h-full purple-gradient-bottom"></div>
          
      </div>
    
    </div>
  )
};

export default HomePage;