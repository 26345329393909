import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ObjectType = {
  title: string,
  description: string,
  keywords: string,
}

export default function useMeta(metaObject: ObjectType) {
  const defaultTitle = 'Van Concept tot Creatie - Uw Partner in Webontwikkeling en Digitale Oplossingen.';
  const defaultDescription = 'Bij WebBeukers begeleiden we uw digitale reis van concept tot realisatie. Ontdek onze unieke Succesroute en maak uw online visie werkelijkheid met onze all-in-one webdiensten.';
  const defaultKeywords = 'Webontwikkeling, Digitale oplossingen, Webdiensten, Front-end development, Back-end development, Weboplossingen, Strategisch webdesign, Innovatieve weboplossingen';
  
  useEffect(() => {
    document.title = metaObject.title || defaultTitle;
    document.querySelector('meta[name="title"]')!.setAttribute('content', metaObject.title || defaultTitle);
    document.querySelector('meta[name="description"]')!.setAttribute('content', metaObject.description || defaultDescription);
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', metaObject.keywords || defaultKeywords);
  }, [defaultTitle, metaObject.title, defaultDescription, metaObject.description, defaultKeywords, metaObject.keywords]);

  return null;
}