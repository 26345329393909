import React from "react";

import { Container, Typography } from "@mui/material";
import ActionButton from "./ActionButton";

import { BounceAnimation, MoveFadeInAnimation } from "../../hooks/useAnimations";

type Props = {
  title? : React.ReactNode,
  children? : React.ReactNode,
  subTitle? : string,
  text? : string,
  buttonText? : string
  buttonLink? : string
  secondaryButtonText? : string
  secondaryButtonLink? : string
}

const Hero = ({ title, subTitle, text, buttonText, buttonLink, secondaryButtonText, secondaryButtonLink }: Props) => {
  return (
    <Container maxWidth="lg" className="m-auto text-center">
      {title !== undefined && title !== null && title !== '' &&
      <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
        <Typography className="font-bold text-center" variant="h1" >
          {title}
        </Typography>
      </MoveFadeInAnimation>
      }
      {subTitle !== undefined && subTitle !== null && subTitle !== '' &&
        <MoveFadeInAnimation delay={200} direction="right" distance="sm" amount={0.5}>
          <Typography className="font-bold text-center" variant="h2">
            {subTitle}
          </Typography>
        </MoveFadeInAnimation>
      }
      {text !== undefined && text !== null && text !== '' &&
        <MoveFadeInAnimation delay={400} direction="right" distance="sm" amount={0.5}>
          <Typography className="text-center py-4">
            {text}
          </Typography>
        </MoveFadeInAnimation>
      }
      <div className="md:flex m-auto w-fit gap-8 overflow-hidden">
        {buttonText !== undefined && buttonText !== null && buttonText !== '' &&
          <MoveFadeInAnimation delay={500} direction="right" distance="sm" amount={0.5}>
            <div className="w-fit m-auto">
              <ActionButton buttonText={buttonText} buttonLink={buttonLink} />
            </div>
          </MoveFadeInAnimation>
        }

        {secondaryButtonText !== undefined && secondaryButtonText !== null && secondaryButtonText !== '' &&
          <MoveFadeInAnimation delay={500} direction="right" distance="sm" amount={0.5}>
            <div className="w-fit m-auto">
              <ActionButton buttonText={secondaryButtonText} buttonLink={secondaryButtonLink} />
            </div>
          </MoveFadeInAnimation>
        }
      </div>
    </Container>
  );
}
 
export default Hero;