import React from 'react';
// import { StrictMode } from "react";
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import './index.css';

import CookieConsent from "react-cookie-consent";

import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//set background color
document.body.style.backgroundColor = "#0F0F10";

root.render(
  <BrowserRouter>
      <Provider store={store}>
      <CookieConsent
          ariaAcceptLabel="Accept cookies"
          disableStyles={true}
          buttonText="Accept"
          buttonClasses="bg-green-btn text-black"
          enableDeclineButton={true}
          declineButtonClasses="bg-purple text-white"
          declineButtonText="Decline"
          onDecline={() => window.history.back()}
        >
          <Typography variant="h5" className="text-white text-center font-bold mb-2">
            Hi there, we use cookies 
          </Typography>
          <Typography className="text-white text-center">
            We just some functional and statistics that help us improve our services. If you’d like to <Link className="text-white white-underline" href="#">read more</Link> about it you can visit our privacy policy page.
        </Typography>
        {/* <Button
          onClick={() => window.history.back()}
          style={{ width: 150, height: 40, backgroundColor: "#5932EA", borderRadius: "15px", padding: "8px 40px", color: "white", textDecoration: "none"}}
        >
          Decline
        </Button> */}
        </CookieConsent>
        <CssBaseline />
      {/* <StrictMode> */}
        {/* <ScrollToTop /> */}
          <App />
        {/* </StrictMode> */}
      </Provider>
  </BrowserRouter>,
);