import { Timeline } from "@mui/lab";
import React from "react";
import TimelineCard from "./TimelineCard";

const TimelineStructure = () => {
  return (
    <Timeline className="pt-36" position="alternate">
      <TimelineCard
        time="Okt"
        activity="Basics"
        details="Start-up"
        description="In October, the focus was on minimum viable product (MVP) development, with an emphasis on basic functions for tenants, adapters, widgets and dashboards. Basic settings and security headers have also been implemented under Auth0, which provides a secure authorization and authentication service."
        year="2023"
        topSeperator={false}
        bottomSeperator={true}
      />
      <TimelineCard
        time="Nov"
        activity="Progress"
        details="In the flow"
        description="A continuation of the basic functionality is expected for November, with the introduction of user rights and the management of queries and filter settings. This is a crucial step in securing dashboard access and customizing the user experience."
        year="2023"
        topSeperator={true}
        bottomSeperator={true}
      />
      <TimelineCard
        time="Dec"
        activity="Flow"
        details="On the right path"
        description="In December, the focus turned to further refining the user experience with features such as audit logs and updates, as well as adapter mapping. This will help monitor user activity and manage the flow of data within the different parts of the dashboard."
        year="2023"
        topSeperator={true}
        bottomSeperator={true}
      />
      <TimelineCard
        time="Jan"
        activity="User Experience"
        details="The next level"
        description="In January, our focus will be on further improving the user experience within our integrated system, which combines Monday.com Kanban, MS Project and our own CRUD features."
        year="2024"
        topSeperator={true}
        bottomSeperator={true}
      />
      <TimelineCard
        time="Feb"
        activity="Management"
        details="Optimization"
        description="In February, our foremost objective is to enhance the user experience within our integrated system. This system brings together User Management, Licensing, DevOps & GitLab Integration and Public Site Onboarding. We're committed to making these components more user-friendly and efficient, ensuring that every interaction with our system is seamless and productive. Our focus is on optimizing the experience for all users and stakeholders as we move forward with these critical integration and enhancement initiatives."
        year="2024"
        topSeperator={true}
        bottomSeperator={true}
      />
      <TimelineCard
        time="Mar"
        activity="Product Launch"
        details="Release"
        description="In March, our primary focus will be on two key initiatives. Firstly, we'll be launching our product on our website, ensuring an engaging and informative online presence. This will showcase the product's features, benefits, and value proposition to visitors. Additionally, we'll also be releasing our product on various social media platforms, including Instagram, Facebook, X and TikTok. We'll create compelling content and engage with our audience on these platforms to generate excitement and awareness about our product, reaching a wide and diverse audience."
        year="2024"
        topSeperator={true}
        bottomSeperator={false}
      />
    </Timeline>
  );
};

export default TimelineStructure;