import Typography from "@mui/material/Typography";
import React from "react";
import { Container } from "@mui/material";

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import useMeta from "../../components/useMeta";
import MailForm from "../../components/elements/Forms/MailForm";

import { BounceAnimation, MoveFadeInAnimation } from "../../hooks/useAnimations";

import CardMedia from '@mui/material/CardMedia';

type Props = any;

const ContactPage = (props: Props) => {
  return (
    useMeta({
      title: 'Contact Us',
      description: 'Contact ActOnIQ for more information about our products and services.',
      keywords: 'contact, actoniq, actoniq contact, contact actoniq',
    }),
    <div>
        <Container className="pt-30 pb-10 md-pt-50 md-pb-10 sm-pt-40 sm-pb-10">
          <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6} className=''>
                <BounceAnimation delay={0} extraclasses="flex flex-col">
                  <CardMedia
                    component="img"
                    alt="Background"
                    image="/svg/actoniq.svg"
                    style={{ width: '80%', height: 'auto', justifyContent: 'center', alignSelf: 'center' }}
                  />
                </BounceAnimation>
              </Grid>
              <Grid item xs={12} md={12} className="text-center mt-12">
                <Item className="flex justify-center">
                  <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                    <Typography className="font-bold" variant="h4">
                      Hi there, we are ActOniQ
                    </Typography>
                  </MoveFadeInAnimation>
                </Item>
              </Grid>
              <Grid item xs={12} md={12} className="text-center">
                <Item className="flex justify-center">
                  <MoveFadeInAnimation direction="right" distance="sm" amount={0.5}>
                    <Typography className="font-bold max-w-3xl" variant="body2">
                      Are you interested in Actoniq’s services or do you have specific questions about our products? Our dedicated team is ready to help you with any information you need. Whether you have questions about our technologies, need support, or simply want to learn more about what we can offer, we are here to assist you.
                    </Typography>
                  </MoveFadeInAnimation>
                </Item>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="text-center mt-12">
              <Item className="flex justify-center items-center">
                  <div className="w-full max-w-xl mx-auto">
                    <MailForm />
                  </div>
              </Item>
            </Grid>
        </Container>
    </div>
  )
};

export default ContactPage;