import { RouteType } from "./config";
import React from "react";
import HomePage from "../pages/home/HomePage";
import AboutPage from "../pages/about/AboutPage";
import ContactPage from "../pages/contact/ContactPage";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";

import Benefits from "../pages/benefits/Benefits";
import Roadmap from "../pages/roadmap/Roadmap";
import Join from "../pages/join/Join";

const Routes: RouteType[] = [
  {
    index: true,
    path: "/",
    displayText: "Home",
    element: <HomePage />,
    state: "home",
    showInMenu: true
  },
  {
    path: "/#about",
    displayText: "About",
    element: <AboutPage />,
    state: "over",
    showInMenu: true
  },
  
  {
    path: "/#benefits",
    displayText: "Benefits",
    element: <Benefits />,
    state: "benefits",
    showInMenu: true
  },
  {
    path: "/#join",
    displayText: "Join us",
    element: <Join />,
    state: "join",
    showInMenu: true
  },
  {
    path: "/roadmap",
    displayText: "Roadmap",
    element: <Roadmap />,
    state: "roadmap",
    showInMenu: true
  },
  {
    path: "/contact",
    displayText: "Contact",
    element: <ContactPage />,
    state: "contact",
    showInMenu: false
  },
  {
    path: "/algemene-voorwaarden-web-beukers",
    displayText: "Algemene voorwaarden",
    element: <TermsAndConditions />,
    state: "terms-and-conditions",
    showInMenu: false
  },
  {
    path: "/privacy-policy",
    displayText: "Privacy Beleid",
    element: <PrivacyPolicy />,
    state: "privacy-policy",
    showInMenu: false
  },
  
];

export default Routes;