import { ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import RoomIcon from '@mui/icons-material/Room';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type Props = {
  displayText : string,
  path: string
  icon: string
  isExternal: boolean
  onClick?: () => void
}

const TopbarItem = ({ displayText, path, icon, isExternal }: Props) => {
  return (
    <ListItemButton
      {...isExternal ? { component: "a", href: path, target: "_blank" } : {component: Link, to: path}}
      sx={{ justifyContent: 'right', flexGrow: 0 }}
      aria-label={displayText}
    >
       <ListItemText
            disableTypography
            primary={
              <Typography className="font-bold" sx={{color:"black"}}>

                {icon !== "" ? (
                  icon === "LocalPhoneIcon" ? (
                    <LocalPhoneIcon />
                  ) : icon === "MailIcon" ? (
                    <MailIcon />
                  ) : icon === "RoomIcon" ? (
                    <RoomIcon />
                  ) : icon === "HelpOutlineIcon" ? (
                    <HelpOutlineIcon />
                  ) : null
                ) : displayText}

              </Typography>
            }
          />
    </ListItemButton>
  )
};

export default TopbarItem;