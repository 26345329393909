import React, { useState } from 'react';
import { Button, TextField, Typography, Container } from '@mui/material';

const MailchimpForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      EMAIL: { value: string };
      NAME: { value: string };
      PHONE: { value: string };
    };
    const formData = {
      EMAIL: target.EMAIL.value,
      NAME: target.NAME.value,
      PHONE: target.PHONE.value,
    };

    try {
      const response = await fetch(`/mailchimp-subscribe`, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const body = await response.json();
      console.log(body);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
      // Vang netwerkfouten op
      setIsSubmitted(false);
    }
  };

  if (isSubmitted) {
    return (
      <Container className='' maxWidth="sm">
        <Typography variant="h5">
          Successfully subscribed to our newsletter!
        </Typography>
      </Container>
    );
  }

  const textFieldStyles = {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  };

  return (
    <Container className='' maxWidth="sm">
      <div id="mc_embed_signup">
        <form onSubmit={handleSubmit} id="mc-embedded-subscribe-form" className="validate">
          <div id="mc_embed_signup_scroll">
            <TextField
              className='bg-btn input-mailchimp'
              required
              id="EMAIL"
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              autoComplete="off"
              sx={textFieldStyles}
            />
            <TextField
              className='bg-btn input-mailchimp'
              id="NAME"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={textFieldStyles}
            />
            <TextField
              className='bg-btn input-mailchimp'
              id="PHONE"
              label="Phone"
              variant="outlined"
              fullWidth
              margin="normal"
              autoComplete="off"
              sx={textFieldStyles}
            />
            <Button type="submit" variant="contained" name="subscribe" id="mc-embedded-subscribe" className="button rounded-full btn-meet text-white font-bold px-8 py-3 w-52 mx-auto" sx={{ mt: 2 }}>
              Subscribe
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default MailchimpForm;