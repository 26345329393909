import React, {useState} from 'react';
import { useFormik } from 'formik';

import Input from '@mui/material/Input';

import ActionButton from '../ActionButton';

import emailTemplateConfigs2 from '../../../configs/emailTemplateConfigs2';

import emailApi from "../../../api/email";

// define types of values
type Values = {
  email: string;
};

// define types of errors
type Errors = {
  email?: string;
};

const validate = (values: Values) => {
  const errors:Errors={};

  // Email Validation
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email address is invalid';
  } else {
    if (errors.email) delete errors.email;
  }

  if (Object.keys(errors).length > 0) {
    // console.log('errors', errors);
  }

  return errors;
};

const InvestorForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // const { executeRecaptcha } = useGoogleReCaptcha();
  
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    
    validate,
    
    onSubmit: async (values) => {
      console.log('values', values);
      setSubmitting(true);
      
      // if (!executeRecaptcha) {
      //   console.log("Execute recaptcha not yet available");
      //   setHasErrors(true);
      //   setErrorMessage('Recaptcha niet vollledig geladen');
      //   return;
      // }
      // executeRecaptcha("contactFormSubmit").then((gReCaptchaToken) => {
        const emailTemplateConfig = emailTemplateConfigs2(values.email);
        
        emailApi.sendEmail(emailTemplateConfig.subject, emailTemplateConfig.html).then((response: any) => {
          // console.log('response', response);
          formik.resetForm();
          setSubmitting(false);
          setSubmitted(true);
        }).catch((error: any) => {
          console.log('error', error);
          setHasErrors(true);
          // setErrorMessage(error.message);
          setErrorMessage('Er is iets mis gegaan met het versturen van het bericht, probeer het later nog eens.');
          setSubmitting(false);
        });
      // });
    }
  });

  // define types for event
  type Event = {
    target: {
      name: string;
      value: string;
    };
  }

  const handleChange = (event: Event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };
  const handleBlur = (event: Event) => {
    formik.setFieldTouched(event.target.name);
  };
  const { errors, touched, values } = formik;

  return (
    <div>
      {isSubmitted ? (
        <div>
          <h1>Bedankt voor uw bericht!</h1>
        </div>
      ) : (
        <div>
          {hasErrors ? (
            <div>
                <h1>{ errorMessage}</h1>
                <div className='w-fit'>
                  <ActionButton buttonText="Refresh" buttonLink="/" />
                </div>
            </div>
          ) : (
            <form className='flex flex-col w-full gap-6' onSubmit={formik.handleSubmit}>
              <div className="flex flex-col sm:flex-row">
                <div className="field">
                  <div className="transition rounded-xl animatingBorder">
                    <Input 
                      className='h-full lg:rounded-bl-full lg:rounded-tl-full rounded-full w-full sm:w-96 bg-slate-500 px-5 focus-visible:outline-0 placeholder:text-slate-300 text-white lg:ml-3'
                      type="email"
                      name="email"
                      placeholder="Email"
                      disableUnderline={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      required
                      autoComplete="off"
                    />
                  </div>
                </div>
                <button className="z-10 rounded-full bg-green-btn text-black font-black px-8 py-3 sm:-ml-16 mt-4 sm:mt-0" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? ('Wordt verstuurd') : ('Become an investor')}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default InvestorForm;