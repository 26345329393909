import React from 'react';

import { Button, Container, ListItemButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import PopoverHover from '../elements/PopoverHover';

import Routes from "../../routes/routes";
import { Link } from 'react-router-dom';

const Footer = () => {
    // Popover for contact info functionality
    // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    // const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
    // const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);

    
    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl2(event.currentTarget);
    // };

    // const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl3(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleClose2 = () => {
    //     setAnchorEl2(null);
    // };
    
    // const handleClose3 = () => {
    //     setAnchorEl3(null);
    // };

    // const open = Boolean(anchorEl);
    // const open2 = Boolean(anchorEl2);
    // const open3 = Boolean(anchorEl3);

    // const id = open ? 'simple-popover' : undefined;
    // const id2 = open ? 'simple-popover' : undefined;
    // const id3 = open ? 'simple-popover' : undefined;

    // List items
    const listItems1 = Routes;

    return (
        <>
            <section className="pb-32 flex">
                <Container>
                    {/* <hr className='border-white' /> */}
                    {/* <Grid container spacing={3} className='mt-10'>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Item>
                                <List className="pb-0 m-0">
                                    <Typography className="text-white font-bold px-0 uppercase pb-2">
                                        Algemeen
                                    </Typography>

                                    {listItems1.map((item, index) => (
                                        item.showInMenu && item.showInMenu === true ?  (
                                        <ListItem key={index} className="p-0 m-0">
                                            {item.path ? (
                                                // <Button href={item.path} variant='text' aria-label={item.displayText} className='px-0 py-0 normal-case'>
                                                //     <ListItemText 
                                                //         primary={item.displayText}
                                                //     />
                                                // </Button>
                                                
                                                    <ListItemButton component={Link} to={item.path} aria-label={item.displayText} sx={{
                                                        paddingX: 0,
                                                    }}>
                                                        {item.displayText}
                                                    </ListItemButton>
                                            ) : (
                                                <ListItemText className='px-0 py-0'
                                                    primary={item.displayText}
                                                />
                                            )}
                                        </ListItem>
                                        ) : null
                                    ))}
                                </List>
                            </Item>
                        </Grid>
                    </Grid> */}
                </Container>    
            </section>
        </>
    );
}
 
export default Footer;