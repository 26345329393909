import client from "./client";

const sendEmail = (Subject: string, Body: string) => client.post("/email/send", { Subject, Body }, { headers: { 'wb-email-token': process.env.REACT_APP_API_HEADER_TOKEN ?? ''} });

// const sendEmailError = (Subject: string, Body: string) => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             reject(new Error("error"));
//         }, 2000);
//     });
// }

export default {
    sendEmail,
    // sendEmailError
};