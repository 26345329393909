import React from "react";

import useMeta from "../../components/useMeta";

type Props = any;

const AboutPage = (props: Props) => {
  return (
    useMeta({
      title: 'ActOnIQ',
      description: '/',
      keywords: '',
    }),
    <div></div>
  )
};

export default AboutPage;