import React, {useState} from 'react';
import { useFormik } from 'formik';


// Unique form Components
import Input from '@mui/material/Input';
import { TextareaAutosize } from '@mui/base';

import ActionButton from '../ActionButton';

import emailTemplateConfigs3 from '../../../configs/emailTemplateConfigs3';

import emailApi from "../../../api/email";

// define types of values
type Values = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

// define types of errors
type Errors = {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
};

const validate = (values: Values) => {
  const errors:Errors={};
  
  // Name Validation
  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length < 2) {
    errors.name = 'Name must be at least 2 characters';
  } else {
    if(errors.name) delete errors.name;
  }

  // Email Validation
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid Email Address';
  } else {
    if (errors.email) delete errors.email;
  }

  // Phone Validation
  if (!values.phone) {
    errors.phone = 'Required';
  } else if (values.phone.length < 10) {
    errors.phone = 'Phone number must be at least 10 characters';
  } else {
    if (errors.phone) delete errors.phone;
  }

  // Message Validation
  if (!values.message) {
    errors.message = 'Required';
  } else if (values.message.length < 10) {
    errors.message = 'Message must be at least 10 characters';
  } else {
    if (errors.message) delete errors.message;
  }

  if (Object.keys(errors).length > 0) {
    // console.log('errors', errors);
  }

  return errors;
};

const MailForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // const { executeRecaptcha } = useGoogleReCaptcha();
  
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    
    validate,
    
    onSubmit: async (values) => {
      console.log('values', values);
      setSubmitting(true);
      
      // if (!executeRecaptcha) {
      //   console.log("Execute recaptcha not yet available");
      //   setHasErrors(true);
      //   setErrorMessage('Recaptcha niet vollledig geladen');
      //   return;
      // }
      // executeRecaptcha("contactFormSubmit").then((gReCaptchaToken) => {
        const emailTemplateConfig3 = emailTemplateConfigs3(values.name, values.email, values.phone, values.message);
        
        emailApi.sendEmail(emailTemplateConfig3.subject, emailTemplateConfig3.html).then((response: any) => {
          // console.log('response', response);
          formik.resetForm();
          setSubmitting(false);
          setSubmitted(true);
        }).catch((error: any) => {
          console.log('error', error);
          setHasErrors(true);
          // setErrorMessage(error.message);
          setErrorMessage('Something went wrong, please try again later.');
          setSubmitting(false);
        });
      // });
    }
  });

  // define types for event
  type Event = {
    target: {
      name: string;
      value: string;
    };
  }

  const handleChange = (event: Event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };
  const handleBlur = (event: Event) => {
    formik.setFieldTouched(event.target.name);
  };
  const { errors, touched, values } = formik;

  return (
    <div className='w-full'>
      {isSubmitted ? (
        <div>
            <h4>Bedankt voor uw bericht!</h4>
        </div>
      ) : (
        <div>
          {hasErrors ? (
            <div>
                <h1>{ errorMessage}</h1>
                <div className='w-fit'>
                  <ActionButton buttonText="Refresh" buttonLink="/contact" />
                </div>
            </div>
          ) : (
            <form className='flex flex-col w-full gap-6' onSubmit={formik.handleSubmit}>
              <div className="field">
                <div className="transition rounded-xl custom-gradient-border p-0.5 animatingBorder animating-border">
                  <Input 
                      className='bg-primary-grey w-full rounded-xl text-white px-4 py-2'
                      type="text"
                      name="name"
                      placeholder="Name*"
                      disableUnderline={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      required
                      autoComplete='off'                          
                  />
                </div>
              </div>
        
              <div className="field">
                <div className="transition rounded-xl custom-gradient-border p-0.5 animatingBorder animating-border">
                  <Input 
                      className='bg-primary-grey w-full rounded-xl text-white px-4 py-2'
                      type="email"
                      name="email"
                      placeholder="Email*"
                      disableUnderline={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      required
                      autoComplete="off"
                  />
                </div>
              </div>
        
              <div className="field">
                <div className="transition rounded-xl custom-gradient-border p-0.5 animatingBorder animating-border">
                  <Input
                    className='bg-primary-grey w-full rounded-xl text-white px-4 py-2'
                    type="number"
                    name="phone"
                    placeholder="Phonenumber*"
                    disableUnderline={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
        
              <div className="field">
                <div className="transition rounded-xl custom-gradient-border p-0.5 animating-border">
                  <TextareaAutosize
                    className='bg-primary-grey w-full rounded-xl text-white px-4 py-2'
                    name="message"
                    placeholder="Message*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minRows={3}
                    value={values.message}
                    required
                    autoComplete="off"
                  />
                </div>
              </div>
              
              <div className='w-fit ml-auto transition duration-300 btn-meet my-2 p-0.5 border-0 rounded-full hover:bg-right'>
                <button className="px-6 py-1 text-center text-white text-lg font-bold m-auto btn-meet rounded-full border-none disabled:opacity-10" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? ('Sending...') : ('Send')}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default MailForm;