import React, { useState } from "react";

import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";

interface TimelineCardProps {
  time: string;
  activity: string;
  details: string;
  description: string;
  year: string;
  topSeperator: boolean;
  bottomSeperator: boolean;
}

const TimelineCard = (Props: TimelineCardProps) => {
  const { time, activity, details, description, year } = Props;
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDescription = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TimelineItem className="flex customTimelineFlexClass">
    <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        className="flex gap-3 md:block md:gap-1"
        align="right"
        variant="body2"
        color="white"
        fontSize={17}
      >
        <TimelineDot className="timeline-dot visible md:invisible" />
          {time} <br />
          {year}
      </TimelineOppositeContent>
        
      <TimelineSeparator className="invisible md:visible">
        <TimelineConnector className={ (!Props.topSeperator) ? 'invisible-seperator timeline-seperator top-seperator' : 'timeline-seperator top-seperator' } />
        <TimelineDot className="timeline-dot" />
        <TimelineConnector className={ (!Props.bottomSeperator) ? 'invisible-seperator timeline-seperator bottom-seperator' : 'timeline-seperator bottom-seperator' } />
      </TimelineSeparator>   
      <TimelineContent className="timeline-card flex flex-col">
        <div className="timeline-card-content w-full max-w-full md:max-w-[500px]">
          <Typography className="font-bold text-left" onClick={toggleDescription}>
            {activity}
          </Typography>
          <Typography className="font-bold text-left" color="grey" onClick={toggleDescription}>
            {details}
          </Typography>
            <Typography variant="body2" className="toggleable-section font-black cursor-default text-left" color="grey">
              {description}
            </Typography>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineCard;