import React from "react";

import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

import Hero from "../../components/elements/Hero";

type Props = any;

const TermsAndConditions = (props: Props) => {
  return (
    <React.Fragment>
      <section className="bg-gradient-to-b from-primary-green to-transparent pb-16 pt-40 lg:pb-40 flex">
        <Container>
          <Hero 
            title="Algemene voorwaarden Webbeukers" 
          />
        </Container>
      </section>

      <section className="pb-32 flex">
        <Container>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Definities</Typography>
                <ol>
                    <li>
                        Web Beukers: Web Beukers, gevestigd te Barneveld onder KvK nr. 89038428.
                    </li>
                    <li>
                        Klant: degene met wie Web Beukers een overeenkomst is aangegaan.
                    </li>
                    <li>
                        Partijen: Web Beukers en klant samen.
                    </li>
                    <li>
                        Consument: een klant die tevens een individu is en die als privé persoon handelt.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold"> Toepasselijkheid algemene voorwaarden</Typography>
                <ol>
                    <li>
                        Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen, werkzaamheden, bestellingen, overeenkomsten en leveringen van diensten of producten door of namens Web Beukers.
                    </li>
                    <li>
                        Partijen kunnen alleen afwijken van deze voorwaarden als zij dat uitdrukkelijk en schriftelijk zijn overeengekomen.
                    </li>
                    <li>
                        Partijen sluiten de toepasselijkheid van aanvullende en/of afwijkende algemene voorwaarden van de klant of van derden uitdrukkelijk uit.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Aanbiedingen en offertes</Typography>
                <ol>
                    <li>
                        Aanbiedingen en offertes van Web Beukers zijn vrijblijvend, tenzij daarin uitdrukkelijk anders vermeld.
                    </li>
                    <li>
                        Een aanbod of offerte is maximaal 2 weken geldig, tenzij een andere aanvaardingstermijn in het aanbod of de offerte staat vermeld.
                    </li>
                    <li>
                        Als de klant een aanbod of offerte niet binnen de geldende termijn aanvaardt, dan vervalt het aanbod of de offerte.
                    </li>
                    <li>
                        Aanbiedingen en offertes gelden niet voor nabestellingen, tenzij partijen dit uitdrukkelijk en schriftelijk zijn overeenkomen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Aanvaarding</Typography>
                <ol>
                    <li>
                        Bij aanvaarding van een vrijblijvende offerte of aanbieding, behoudt Web Beukers zich het recht voor de offerte of het aanbod alsnog binnen 3 dagen na ontvangst van de aanvaarding in te trekken, zonder dat de klant hieraan enige rechten kan ontlenen.
                    </li>
                    <li>
                        Mondelinge aanvaarding van de klant verbindt Web Beukers slechts, nadat de klant deze schriftelijk (of elektronisch) heeft bevestigd.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Prijzen</Typography>
                <ol>
                    <li>
                        Alle prijzen die Web Beukers hanteert zijn in euro’s, zijn exclusief btw en exclusief eventuele overige kosten zoals administratiekosten, heffingen en reis-, verzend- of transportkosten, tenzij uitdrukkelijk anders vermeld of anders overeengekomen.
                    </li>
                    <li>
                        Alle prijzen op die Web Beukers hanteert voor zijn producten of diensten, op zijn website of die anderszins kenbaar zijn gemaakt, kan Web Beukers te allen tijde wijzigen.
                    </li>
                    <li>
                        Verhogingen van de kostprijzen van producten of onderdelen daarvan, die Web Beukers niet kon voorzien ten tijde van het doen van de aanbieding c.q. het tot stand komen van de overeenkomst, kunnen aanleiding geven tot prijsverhogingen.
                    </li>
                    <li>
                        De consument heeft het recht om een overeenkomst te ontbinden als gevolg van een prijsverhoging zoals bedoeld in lid 3, tenzij de verhoging het gevolg is van een wettelijke regeling.
                    </li>
                    <li>
                        De prijs met betrekking tot een dienstverlening wordt door Web Beukers vastgesteld op grond van de werkelijk bestede uren.
                    </li>
                    <li>
                        De prijs wordt berekend volgens de gebruikelijke uurtarieven van Web Beukers, geldend voor de periode waarin hij de werkzaamheden verricht, tenzij een afwijkend uurtarief is overeengekomen.
                    </li>
                    <li>
                        Indien partijen voor een dienstverlening door Web Beukers een totaalbedrag zijn overeengekomen, is dit altijd een richtprijs, tenzij partijen uitdrukkelijk en schriftelijk een vaste prijs, waarvan niet kan worden afgeweken, zijn overeengekomen.
                    </li>
                    <li>
                        Web Beukers is gerechtigd om tot 10% van de richtprijs af te wijken.
                    </li>
                    <li>
                        Indien de richtprijs meer dan 10% hoger uit gaat vallen, dient Web Beukers de klant tijdig te laten weten waarom een hogere prijs gerechtvaardigd is.
                    </li>
                    <li>
                        Indien de richtprijs meer dan 10% hoger uit gaat vallen, heeft de klant het recht om het deel van de opdracht te laten vervallen, dat boven de richtprijs vermeerderd met 10% uitkomt.
                    </li>
                    <li>
                        Web Beukers heeft het recht de prijzen jaarlijks aan te passen.
                    </li>
                    <li>
                        Voorafgaand aan de ingang ervan zal Web Beukers prijsaanpassingen meedelen aan de klant.
                    </li>
                    <li>
                        De consument heeft het recht om de overeenkomst met Web Beukers op te zeggen indien hij niet akkoord gaat met de prijsverhoging.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Strippenkaart(en) / PrioPaid</Typography>
                <ol>
                    <li>
                        Één strip staat voor één werk uur.
                    </li>
                    <li>
                        Strippenkaarten zijn onbeperkt geldig.
                    </li>
                    <li>
                        Aangekochte strippen zijn niet refundable.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Betalingen en betalingstermijn</Typography>
                <ol>
                    <li>
                        Web Beukers mag bij het aangaan van de overeenkomst een aanbetaling tot 50% van het overeengekomen bedrag verlangen.
                    </li>
                    <li>
                        De klant dient betalingen achteraf binnen 7 dagen na levering van het product te hebben voldaan.
                    </li>
                    <li>
                        Betalingstermijnen worden beschouwd als fatale betalingstermijnen. Dat betekent dat indien de klant het overeengekomen bedrag niet uiterlijk op de laatste dag van de betalingstermijn heeft voldaan, hij van rechtswege in verzuim en in gebreke is, zonder dat Web Beukers de klant een aanmaning hoeft te sturen c.q. in gebreke hoeft te stellen.
                    </li>
                    <li>
                        Web Beukers behoudt zich het recht voor om een levering afhankelijk te stellen van onmiddellijke betaling danwel een zekerheidstelling te eisen voor het totale bedrag van de diensten of producten.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Gevolgen niet tijdig betalen</Typography>
                <ol>
                    <li>
                        Betaalt de klant niet binnen de overeengekomen termijn, dan is Web Beukers gerechtigd een rente van 1% per maand in rekening te brengen vanaf de dag dat de klant in verzuim is, waarbij een gedeelte van een maand voor een hele maand wordt gerekend.
                    </li>
                    <li>
                        Wanneer de klant in verzuim is, is hij bovendien buitengerechtelijke incassokosten en eventuele schadevergoeding verschuldigd aan Web Beukers.
                    </li>
                    <li>
                        De incassokosten worden berekend aan de hand van het Besluit vergoeding voor buitengerechtelijke incassokosten.
                    </li>
                    <li>
                        Wanneer de klant niet tijdig betaalt, mag Web Beukers zijn verplichtingen opschorten totdat de klant aan zijn betalingsverplichting heeft voldaan.
                    </li>
                    <li>
                        In geval van liquidatie, faillissement, beslag of surseance van betaling aan de zijde van de klant, zijn de vorderingen van Web Beukers op de klant onmiddellijk opeisbaar.
                    </li>
                    <li>
                        Weigert de klant zijn medewerking aan de uitvoering van de overeenkomst door Web Beukers, dan is hij nog steeds verplicht de afgesproken prijs aan Web Beukers te betalen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Recht van reclame</Typography>
                <ol>
                    <li>
                        Zodra de klant in verzuim is, is Web Beukers gerechtigd het recht van reclame in te roepen ten aanzien van de onbetaalde aan de klant geleverde producten.
                    </li>
                    <li>
                        Web Beukers roept het recht van reclame in door middel van een schriftelijke of elektronische mededeling.
                    </li>
                    <li>
                        Zodra de klant op de hoogte is gesteld van het ingeroepen recht van reclame, dient de klant de producten waar dit recht betrekking op heeft, onmiddellijk te retourneren aan Web Beukers, tenzij partijen hierover andere afspraken maken.
                    </li>
                    <li>
                        De kosten voor het terughalen of -brengen van de producten komen voor rekening van de klant.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Herroepingsrecht</Typography>
                <ol>
                    <li>Een consument kan een online aankoop gedurende een bedenktijd van 14 dagen zonder opgave van reden ontbinden op voorwaarde dat:</li>
                    <ul>
                        <li>
                            het product niet is gebruikt
                        </li>
                        <li>
                            het geen product is dat snel kan bederven, zoals voedsel of bloemen
                        </li>
                        <li>
                            het geen product is dat speciaal voor de consument op maat is gemaakt of aangepast
                        </li>
                        <li>
                            het geen product is dat niet kan worden teruggestuurd om hygiënische redenen (ondergoed, badkleding, enz.)
                        </li>
                        <li>
                            de verzegeling nog intact is, indien het gaat om gegevensdragers met digitale inhoud (dvd’s, cd’s, etc.)
                        </li>
                        <li>
                            het product geen reis, vervoersticket, cateringopdracht of vorm van vrijetijdsbesteding is
                        </li>
                        <li>
                            het product geen los tijdschrift of losse krant is
                        </li>
                        <li>
                            het geen (opdracht tot) spoedreparatie betreft
                        </li>
                        <li>
                            de consument niet heeft afgezien van zijn herroepingsrecht
                        </li>
                    </ul>

                    <li>De bedenktijd van 14 dagen zoals in lid 1 genoemd, vangt aan:</li>
                    <ul>
                        <li>
                            op de dag nadat de consument het laatste product of onderdeel heeft ontvangen van 1 bestelling
                        </li>
                        <li>
                            zodra de consument het eerste product bij een abonnement heeft ontvangen
                        </li>
                        <li>
                            zodra de consument een dienst voor het eerst heeft afgenomen
                        </li>
                        <li>
                            zodra de consument bevestigd heeft dat hij digitale inhoud via internet gaat afnemen
                        </li>
                    </ul>

                    <li>
                        De consument kan zijn beroep op het herroepingsrecht kenbaar maken via support@webbeukers.nl, indien gewenst met behulp van het herroepingsformulier dat via de website van Web Beukers, 
                        <a className="c-secondary c-h-secondary" href="https://webbeukers.nl.">https://webbeukers.nl</a>
                        , kan worden gedownload.
                    </li>

                    <li>
                        De consument is verplicht om het product binnen 14 dagen na het kenbaar maken van zijn herroepingsrecht te retourneren aan Web Beukers, bij gebreke waarvan zijn herroepingsrecht komt te vervallen.
                    </li>
                    <li>
                        De kosten voor retourneren komen alleen voor rekening van Web Beukers indien de volledige bestelling wordt geretourneerd.
                    </li>
                    <li>
                        Indien de aankoopkosten en eventuele overige kosten (zoals verzend- en retourkosten) volgens de wet voor terugbetaling in aanmerking komen, zal Web Beukers deze kosten binnen 14 dagen na ontvangst van het tijdige beroep op het herroepingsrecht, terugbetalen aan de consument, op voorwaarde dat de consument het product tijdig aan Web Beukers heeft geretourneerd.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Opschortingsrecht</Typography>
                <p>
                    Tenzij de klant een consument is, doet de klant afstand van het recht om de nakoming van enige uit deze overeenkomst voortvloeiende verbintenis op te schorten.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Retentierecht</Typography>
                <ol>
                    <li>
                        Web Beukers kan een beroep doen op zijn retentierecht en in dat geval producten van de klant onder zich houden, totdat de klant alle nog openstaande rekeningen ten aanzien van Web Beukers heeft voldaan, tenzij de klant voor die kosten voldoende zekerheid heeft gesteld.
                    </li>
                    <li>
                        De retentierecht geldt eveneens op grond van eerdere overeenkomsten waaruit de klant nog betalingen verschuldigd is aan Web Beukers.
                    </li>
                    <li>
                        Web Beukers is nooit aansprakelijk voor eventuele schade die de klant mogelijkerwijs lijdt als gevolg van het gebruikmaken van zijn retentierecht.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Verrekening</Typography>
                <p>
                    Tenzij de klant een consument is, doet de klant afstand van zijn recht om een schuld aan Web Beukers te verrekenen met een vordering op Web Beukers.                 
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Eigendomsvoorbehoud</Typography>
                <ol>
                    <li>
                        Web Beukers blijft eigenaar van alle geleverde producten totdat de klant volledig heeft voldaan aan al zijn betalingsverplichtingen ten aanzien van Web Beukers op grond van wat voor met Web Beukers gesloten overeenkomst dan ook, met inbegrip van vorderingen inzake het tekortschieten in de nakoming.
                    </li>
                    <li>
                        Tot die tijd kan Web Beukers zich beroepen op zijn eigendomsvoorbehoud en de zaken terugnemen.
                    </li>
                    <li>
                        Voordat het eigendom is overgegaan op de klant, mag de klant de producten niet verpanden, verkopen, vervreemden of anderszins bezwaren.
                    </li>
                    <li>
                        Indien Web Beukers een beroep doet op zijn eigendomsvoorbehoud, geldt de overeenkomst als ontbonden en heeft Web Beukers het recht om schadevergoeding, gederfde winst en rente te vorderen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Levering</Typography>
                <ol>
                    <li>
                        Levering vindt plaats zolang de voorraad strekt.
                    </li>
                    <li>
                        Levering vindt plaats bij Web Beukers, tenzij partijen anders zijn overeengekomen.
                    </li>
                    <li>
                        Levering van online bestelde producten vindt plaats op het door de klant aangegeven adres.
                    </li>
                    <li>
                        Indien de overeengekomen bedragen niet of niet op tijd worden voldaan, heeft Web Beukers het recht om zijn verplichtingen op te schorten totdat het overeengekomen deel alsnog is voldaan.
                    </li>
                    <li>
                        Bij te late betaling is er sprake van schuldeisersverzuim, met als gevolg dat de klant een verlate levering niet aan Web Beukers kan tegenwerpen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Levertijd</Typography>
                <ol>
                    <li>
                        De door Web Beukers opgegeven levertijden zijn indicatief en geven de klant bij overschrijding daarvan geen recht op ontbinding of schadevergoeding, tenzij partijen uitdrukkelijk en schriftelijk anders zijn overeengekomen.
                    </li>
                    <li>
                        De levertijd vangt aan nadat de door de klant voor akkoord getekende offerte aan Web Beukers door Web Beukers schriftelijk danwel elektronisch is bevestigd aan de klant.
                    </li>
                    <li>
                        Overschrijding van de opgegeven levertijd geeft de klant geen recht op schadevergoeding en evenmin het recht om de overeenkomst te ontbinden, tenzij Web Beukers niet binnen 14 dagen na daartoe schriftelijk te zijn aangemaand kan leveren of partijen hierover iets anders hebben afgesproken.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Feitelijke levering</Typography>
                <p>
                    De klant dient ervoor zorg te dragen dat de feitelijke levering van de door hem bestelde producten tijdig kan plaatsvinden.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Transportkosten</Typography>
                <p>
                    Transportkosten zijn voor rekening van de klant, tenzij partijen hierover iets anders hebben afgesproken.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Verpakking en verzending</Typography>
                <ol>
                    <li>
                        Indien de verpakking van een geleverd product geopend of beschadigd is, dan dient de klant, alvorens het product in ontvangst te nemen, hiervan door de expediteur c.q. bezorger een aantekening op te laten maken, bij gebreke waarvan Web Beukers niet aansprakelijk kan worden gehouden voor eventuele schade.
                    </li>
                    <li>
                        Indien de klant zelf voor transport van een product zorgdraagt, dient hij eventuele zichtbare beschadigingen aan producten of de verpakking voorafgaand aan het vervoer te melden aan Web Beukers, bij gebreke waarvan Web Beukers niet aansprakelijk kan worden gehouden voor eventuele schade.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Verzekering</Typography>
                <ol>
                    <li>
                        De klant verplicht zich de volgende zaken voldoende te verzekeren en verzekerd te houden tegen onder andere brand, ontploffings- en waterschade evenals diefstal:
                    </li>
                    <ul>
                        <li>
                            geleverde zaken die noodzakelijk zijn voor de uitvoering van de onderliggende overeenkomst
                        </li>
                        <li>
                            zaken van Web Beukers die bij de klant aanwezig zijn
                        </li>
                        <li>
                            zaken die onder eigendomsvoorbehoud zijn geleverd
                        </li>
                    </ul>
                    <li>
                        De klant geeft op eerste verzoek van Web Beukers de polis van deze verzekeringen ter inzage.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Bewaring</Typography>
                <ol>
                    <li>
                        Indien de klant bestelde producten pas later afneemt dan de overeengekomen leveringsdatum, is het risico van een eventueel kwaliteitsverlies geheel voor de klant.
                    </li>
                    <li>
                        Eventuele extra kosten als gevolg van voortijdige danwel verlate afname van producten komen geheel voor rekening van de klant.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Montage/Installatie</Typography>
                <p>
                    Hoewel Web Beukers zich inspant alle montage en/of installatiewerkzaamheden zo goed mogelijk uit te voeren, draagt hij hiervoor geen enkele verantwoordelijkheid behalve in het geval van opzet of grove schuld.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Garantie</Typography>
                <ol>
                    <li>
                        Wanneer partijen een overeenkomst met een dienstverlenend karakter zijn aangegaan, bevat deze voor Web Beukers enkel inspanningsverplichtingen, geen resultaatsverplichtingen.
                    </li>
                    <li>
                        De garantie met betrekking tot producten is uitsluitend van toepassing op defecten, veroorzaakt door ondeugdelijk(e) fabricage, constructie of materiaal.
                    </li>
                    <li>
                        De garantie geldt niet in het geval van normale slijtage en van schade ontstaan als gevolg van ongevallen, aangebrachte wijzigingen aan het product, nalatigheid of ondeskundig gebruik door de klant, alsmede wanneer de oorzaak van het defect niet duidelijk kan worden vastgesteld.
                    </li>
                    <li>
                        Het risico van verlies, beschadiging of diefstal van de producten die het voorwerp zijn van een overeenkomst tussen partijen, gaat over op de klant op het moment waarop deze juridisch en/of feitelijk worden geleverd, althans in de macht van de klant komen of van een derde die het product ten behoeve van de klant in ontvangst neemt.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Uitvoering van de overeenkomst</Typography>
                <ol>
                    <li>
                        Web Beukers voert de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uit.
                    </li>
                    <li>
                        Web Beukers heeft het recht om de overeengekomen dienstverlening (gedeeltelijk) te laten verrichten door derden.
                    </li>
                    <li>
                        De uitvoering van de overeenkomst geschiedt in onderling overleg en na schriftelijk akkoord en betaling van het eventueel afgesproken voorschot door de klant.
                    </li>
                    <li>
                        Het is de verantwoordelijkheid van de klant dat Web Beukers tijdig kan beginnen aan de uitvoering van de overeenkomst.
                    </li>
                    <li>
                        Indien de klant er niet voor heeft gezorgd dat Web Beukers tijdig kan beginnen aan de uitvoering van de overeenkomst, komen de daaruit voortvloeiende extra kosten en/of extra uren voor rekening van de klant.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Informatieverstrekking door de klant</Typography>
                <ol>
                    <li>
                        De klant stelt alle informatie, gegevens en bescheiden die relevant zijn voor de correcte uitvoering van de overeenkomst tijdig en in gewenste vorm en op gewenste wijze beschik&shy;baar aan Web Beukers.
                    </li>
                    <li>
                        De klant staat in voor de juistheid, volledigheid en betrouwbaarheid van de ter beschikking gestelde informatie, gegevens en bescheiden, ook indien deze van derden afkomstig zijn, voor zover uit de aard van de overeenkomst niet anders voortvloeit.
                    </li>
                    <li>
                        Indien en voor zover de klant dit verzoekt, retourneert Web Beukers de betreffende bescheiden.
                    </li>
                    <li>
                        Stelt de klant niet, niet tijdig of niet behoorlijk de door Web Beukers redelijkerwijs verlangde informatie, gegevens of bescheiden beschikbaar en loopt de uitvoering van de overeenkomst hierdoor vertraging op, dan komen de daaruit voortvloeiende extra kosten en extra uren voor rekening van de klant.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Duur van de overeenkomst</Typography>
                <ol>
                    <li>
                        De overeenkomst tussen Web Beukers en de klant wordt aangegaan voor onbepaalde tijd, tenzij uit de aard van de overeenkomst iets anders voortvloeit of partijen uitdrukkelijk en schriftelijk anders zijn overeengekomen.
                    </li>
                    <li>
                        Indien een overeenkomst voor bepaalde tijd is aangegaan, dan wordt deze na afloop van de termijn stilzwijgend omgezet in een overeenkomst voor onbepaalde tijd, tenzij 1 van de partijen de overeenkomst opzegt met inachtneming van een opzegtermijn van 3 maanden, c.q. een consument de overeenkomst opzegt met inachtneming van een opzegtermijn van 1 maand dan eindigt de overeenkomst van rechtswege.
                    </li>
                    <li>
                        Zijn partijen binnen de looptijd van de overeenkomst voor de voltooiing van bepaalde werk&shy;zaamheden een termijn overeengekomen, dan is dit nooit een fatale termijn. Bij over&shy;schrijding van deze termijn moet de klant Web Beukers schriftelijk in gebreke stellen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Opzeggen overeenkomst voor onbepaalde tijd</Typography>
                <ol>
                    <li>
                        De klant kan een overeenkomst die voor onbepaalde tijd is aangegaan te allen tijde opzeggen met inachtneming van een opzegtermijn van 3 maanden.
                    </li>
                    <li>
                        Een consument heeft het recht een overeenkomst voor onbepaalde tijd op te zeggen met inachtneming van een opzegtermijn van 1 maand.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Intellectueel eigendom</Typography>
                <ol>
                    <li>
                        Web Beukers behoudt alle intellectuele eigendomsrechten (waaronder auteursrecht, octrooirecht, merkenrecht, tekeningen- en modellen&shy;recht, etc.) op alle ontwerpen, tekeningen, geschriften, dragers met gegevens of andere informatie, offertes, afbeeldingen, schetsen, modellen, maquettes, etc., tenzij partijen schriftelijk anders zijn overeengekomen.
                    </li>
                    <li>
                        De klant mag genoemde intellectuele eigendomsrechten niet zonder voorafgaande schriftelijke toestemming van Web Beukers (laten) kopiëren, aan derden tonen en/of ter beschikking stellen of op andere wijze gebruiken.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Geheimhouding</Typography>
                <ol>
                    <li>
                        De klant houdt iedere informatie die hij (in welke vorm dan ook) van Web Beukers ontvangt geheim.
                    </li>
                    <li>
                        Hetzelfde geldt voor alle andere informatie betreffende Web Beukers waarvan hij weet of redelijker&shy;wijs kan vermoeden dat deze geheim of vertrouwelijk is, dan wel waarvan hij kan verwachten dat verspreiding ervan Web Beukers schade kan berokkenen.
                    </li>
                    <li>
                        De klant neemt alle nodige maatregelen om te waarborgen dat hij de in lid 1 en 2 genoemde informatie ook geheim houdt.
                    </li>
                    <li>
                        De in dit artikel omschreven geheimhoudingsplicht geldt niet voor informatie:
                    </li>
                    <ul>
                        <li>
                            die al openbaar was voordat de klant deze informatie vernam of die later openbaar is geworden zonder dat dit het gevolg was van een schending van de geheimhoudingsplicht van de klant
                        </li>
                        <li>
                            die door de klant openbaar gemaakt wordt op grond van een wettelijke plicht
                        </li>
                    </ul>
                    <li>
                        De in dit artikel omschreven geheimhoudingsplicht geldt voor de duur van de onderliggende overeenkomst en voor een periode van 3 jaar na afloop daarvan.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Boetebeding</Typography>
                <ol>
                    <li>
                        Indien de andere partij het artikel van deze algemene voorwaarden over geheimhouding of over intellectueel eigendom overtreedt, dan verbeurt hij voor elke overtreding ten behoeve van handelsnaam een onmiddellijk opeisbare boete.
                    </li>
                    <ul className="mtb-small-spacing">
                        <li>
                            is de andere partij een consument dan bedraagt deze boete € 1.000
                        </li>
                        <li>
                            is de andere partij een rechtspersoon dan bedraagt deze boete € 5.000
                        </li>
                    </ul>
                    <li>
                        Daarnaast verbeurt de andere partij een bedrag ad 5% van het in lid 1 genoemde bedrag voor elke dag dat die overtreding voortduurt.
                    </li>
                    <li>
                        Voor het verbeuren van deze boete is geen voorafgaande ingebrekestelling of gerechtelijke procedure nodig. Ook hoeft er geen sprake te zijn van enige vorm van schade.
                    </li>
                    <li>
                        Het verbeuren van de in het eerste lid van dit artikel bedoelde boete doet geen afbreuk aan de overige rechten van Web Beukers waaronder zijn recht om naast de boete schadevergoeding te vorderen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Vrijwaring</Typography>
                <p>
                    De klant vrijwaart Web Beukers tegen alle aanspraken van derden die verband houden met de door Web Beukers geleverde producten en/of diensten.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Klachten</Typography>
                <ol>
                    <li>
                        De klant dient een door Web Beukers geleverd product of verleende dienst zo spoedig mogelijk te onderzoeken op eventuele tekortkomingen.
                    </li>
                    <li>
                        Beantwoordt een geleverd product of verleende dienst niet aan hetgeen de klant redelijkerwijs van de overeenkomst mocht verwachten, dan dient de klant Web Beukers daarvan zo spoedig mogelijk, doch in ieder geval binnen 1 maand na constatering van de tekortkomingen, op de hoogte te stellen.
                    </li>
                    <li>
                        Consumenten dienen Web Beukers uiterlijk binnen 2 maanden na constatering van de tekortkomingen hiervan op de hoogte te stellen.
                    </li>
                    <li>
                        De klant geeft daarbij een zo gedetailleerd mogelijke omschrijving van de tekort&shy;koming, zodat Web Beukers in staat is hierop adequaat te reageren.
                    </li>
                    <li>
                        De klant dient aan te tonen dat de klacht betrekking heeft op een overeenkomst tussen partijen.
                    </li>
                    <li>
                        Indien een klacht betrekking heeft op lopende werkzaamheden, kan dit er in ieder geval niet toe leiden dat Web Beukers gehouden kan worden om andere werkzaamheden te verrichten dan zijn overeengekomen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Ingebrekestelling</Typography>
                <ol>
                    <li>
                        De klant dient ingebrekestellingen schriftelijk kenbaar te maken aan Web Beukers.
                    </li>
                    <li>
                        Het is de verantwoordelijkheid van de klant dat een ingebrekestelling Web Beukers ook daadwerkelijk (tijdig) bereikt.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Hoofdelijke aansprakelijkheid klant </Typography>
                <p>
                    Als Web Beukers een overeenkomst aangaat met meerdere klanten, is ieder van hen hoofdelijk aansprakelijk voor de volledige bedragen die zij op grond van die overeenkomst aan Web Beukers verschuldigd zijn.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Aansprakelijkheid Web Beukers</Typography>
                <ol>
                    <li>
                        Web Beukers is uitsluitend aansprakelijk voor enige schade die de klant lijdt indien en voor zover die schade is veroorzaakt door opzet of bewuste roekeloosheid.
                    </li>
                    <li>
                        Indien Web Beukers aansprakelijk is voor enige schade, is het slechts aansprakelijk voor directe schade die voortvloeit uit of verband houdt met de uitvoering van een overeenkomst.
                    </li>
                    <li>
                        Web Beukers is nooit aansprakelijk voor indirecte schade, zoals gevolgschade, gederfde winst, gemiste besparingen of schade aan derden.
                    </li>
                    <li>
                        Indien Web Beukers aansprakelijk is, is deze aansprakelijkheid beperkt tot het bedrag dat door een gesloten (beroeps)aansprakelijkheidsverzekering wordt uitbetaald en bij gebreke van (volledige) uitkering door een verzekeringsmaatschappij van het schadebedrag is de aansprakelijkheid beperkt tot het (gedeelte van het) factuurbedrag waarop de aansprakelijkheid betrekking heeft.
                    </li>
                    <li>
                        Alle afbeeldingen, foto’s, kleuren, tekeningen, omschrijvingen op de website of in een catalogus zijn slechts indicatief en gelden slechts bij benadering en kunnen geen aanleiding zijn tot schadevergoeding en/of (gedeeltelijke) ontbinding van de overeenkomst en/of opschorting van enige verplichting.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Vervaltermijn </Typography>
                <p>
                    Elk recht van de klant op schadevergoeding van Web Beukers vervalt in elk geval 12 maanden na de gebeurtenis waaruit de aansprakelijkheid direct of indirect voortvloeit. Hiermee wordt niet uitgesloten het bepaalde in artikel 6:89 van het Burgerlijk Wetboek.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Recht op ontbinding</Typography>
                <ol>
                    <li>
                        De klant heeft het recht de overeenkomst te ontbinden wanneer Web Beukers toerekenbaar tekortschiet in de nakoming van zijn verplichtingen, tenzij deze tekortkoming, gezien haar bijzondere aard of geringe betekenis, de ontbinding niet rechtvaardigt.
                    </li>
                    <li>
                        Is de nakoming van de verplichtingen door Web Beukers niet blijvend of tijdelijk onmogelijk, dan kan ontbinding pas plaatsvinden nadat Web Beukers in verzuim is.
                    </li>
                    <li>
                        Web Beukers heeft het recht de overeenkomst met de klant te ontbinden, indien de klant zijn verplichtingen uit de overeenkomst niet volledig of niet tijdig nakomt, danwel indien Web Beukers kennis heeft genomen van omstandigheden die hem goede grond geven om te vrezen dat de klant zijn verplichtingen niet behoorlijk zal kunnen nakomen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Overmacht</Typography>
                <ol>
                    <li>
                        In aanvulling op het bepaalde in artikel 6:75 Burgerlijk Wetboek geldt dat een tekortkoming van Web Beukers in de nakoming van enige verplichting ten aanzien van de klant niet aan Web Beukers kan worden toegerekend in een van de wil van Web Beukers onafhankelijke situatie, waardoor de nakoming van zijn verplichtingen ten aanzien van de klant geheel of gedeeltelijk wordt verhinderd of waardoor de nakoming van zijn verplichtingen in redelijk&shy;heid niet van Web Beukers kan worden verlangd.
                    </li>
                    <li>
                        Tot de in lid 1 genoemde overmachtsituatie worden ook – doch niet uitsluitend – gerekend: noodtoestand (zoals burgeroorlog, opstand, rellen, natuurrampen, etc.); wanprestaties en overmacht van toeleveranciers, bezorgers of andere derden; onverwachte stroom-, elektriciteits- internet-, computer- en telecomstoringen; computer&shy;virussen, stakingen, overheidsmaatregelen, onvoorziene vervoersproblemen, slechte weersomstandigheden en werkonderbrekingen.
                    </li>
                    <li>
                        Indien zich een overmachtsituatie voordoet waardoor Web Beukers 1 of meer verplichtingen naar de klant niet kan nakomen, dan worden die verplichtingen opgeschort totdat Web Beukers er weer aan kan voldoen.
                    </li>
                    <li>
                        Vanaf het moment dat een overmachtsituatie tenminste 30 kalenderdagen heeft geduurd, mogen beide partijen de overeenkomst schriftelijk geheel of gedeeltelijk ontbinden.
                    </li>
                    <li>
                        Web Beukers is in een overmachtsituatie geen enkele (schade)vergoeding verschuldigd, ook niet als het als gevolg van de overmachttoestand enig voordeel geniet.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Wijziging van de overeenkomst </Typography>
                <p>
                    Indien na het afsluiten van de overeenkomst voor de uitvoering ervan het nodig blijkt om de inhoud ervan te wijzigen of aan te vullen, passen partijen tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan.
                </p>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Wijziging algemene voorwaarden</Typography>
                <ol>
                    <li>
                        Web Beukers is gerechtigd deze algemene voorwaarden te wijzigen of aan te vullen.
                    </li>
                    <li>
                        Wijzigingen van ondergeschikt belang kunnen te allen tijde worden doorgevoerd.
                    </li>
                    <li>
                        Grote inhoudelijke wijzigingen zal Web Beukers zoveel mogelijk vooraf met de klant bespreken.
                    </li>
                    <li>
                        Consumenten zijn gerechtigd bij een wezenlijke wijziging van de algemene voorwaarden de overeenkomst op te zeggen.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Overgang van rechten</Typography>
                <ol>
                    <li>
                        Rechten van de klant uit een overeenkomst tussen partijen kunnen niet aan derden worden overgedragen zonder de voorafgaande schriftelijke instemming van Web Beukers.
                    </li>
                    <li>
                        Deze bepaling geldt als een beding met goederenrechtelijke werking zoals bedoeld in artikel 3:83, tweede lid, Burgerlijk Wetboek.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Gevolgen nietigheid of vernietigbaarheid</Typography>
                <ol>
                    <li>
                        Wanneer één of meerdere bepalingen van deze algemene voorwaarden nietig of vernietigbaar blijken, dan tast dit de overige bepalingen van deze voorwaarden niet aan.
                    </li>
                    <li>
                        Een bepaling die nietig of vernietigbaar is, wordt in dat geval vervangen door een bepaling die het dichtst in de buurt komt van wat Web Beukers bij het opstellen van de voorwaarden op dat punt voor ogen had.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography variant="h4" className="font-bold" >Toepasselijk recht en bevoegde rechter</Typography>
                <ol>
                    <li>
                        Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands recht van toepassing.
                    </li>
                    <li>
                        De Nederlandse rechter in het arrondissement waar Web Beukers is gevestigd / praktijk houdt / kantoor houdt is exclusief bevoegd om kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet dwingend anders voorschrijft.
                    </li>
                </ol>
            </div>
            <div className="mb-4">
                <Typography> 
                    <i>
                        Opgesteld op 01 januari 2020.
                    </i>
                </Typography> 
            </div>
        </Container>
      </section>     
    </React.Fragment>
  )
};

export default TermsAndConditions;