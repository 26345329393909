import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import React from "react";

import { browserName } from 'react-device-detect';

import Topbar from "../common/Topbar";
import { routes } from "../../routes";
import Footer from "../common/Footer";


const MainLayout = () => {
  return (
    <Box className={browserName + " relative"}>
      <Box
        component="nav"
        sx={{
          flexShrink: 0
        }}
      >
        <Topbar collection={routes} />
      </Box>
      <Box className=""
        component="main"
        sx={{
          flexGrow: 1,
          top: sizeConfigs.topbar.height,
          minHeight: "100vh",
        }}
      >
        <div className="absolute top-0 right-0 aspect-square w-4/5 -z-10 overflow-hidden">
          <div className="w-full h-full purple-gradient"></div>
        </div>

        <div className="absolute top-[15%] left-0 aspect-square w-3/5 -z-10 overflow-hidden">
          <div className="w-full h-full purple-gradient-left"></div>
        </div>

        <div className="absolute top-[40%] left-[10%] aspect-square w-4/5 -z-10 overflow-hidden">
          <div className="w-full h-full purple-gradient-middle"></div>
        </div>
        <Outlet />
      </Box>
      <Box
        component="footer"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;