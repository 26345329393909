import React, { useEffect, useState } from 'react';
import { useRef } from 'react';

import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import Container from '@mui/material/Container';

interface CustomCardProps {
  category: string;
  title: string;
  content: string;
  link: string;
}

const CustomCard: React.FC<CustomCardProps> = ({ title, category, content, link }) => {
  return (
    <React.Fragment>
      <Typography className="text-xs text-primary-purple font-bold mb-2 text-center">
      {category}
      </Typography>
      <a href={link} target="blank">
        <Card className="flex flex-col shadow-lg rounded-2xl overflow-hidden h-full">
          <div className="bg-purple p-2">
            <Typography className="text-m font-bold text-white text-center">
              {title}
            </Typography>
          </div>
          <CardContent className="flex-1 p-4 text-center flex flex-col justify-between">
            <Typography className="text-gray-800 text-m font-semibold">
              {content}
            </Typography>
          </CardContent>
        </Card>
      </a>
      <Button
        size="small"
        component="a"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-white mt-1 mx-auto w-full hover:bg-transparent"
      >
        Read full article
        <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2'>
          <path d="M0.405029 0.304199L3.81012 3.95155L0.405029 7.5989L0.405046 3.95155L0.405029 0.304199Z" fill="#5AF25F"/>
        </svg>

      </Button>
    </React.Fragment>
  );
};

const CardContainer = () => {

  // const [activeFilter, setActiveFilter] = useState('All');
  
  const getAllFilterElements = (filters:any) => {
    const filtersForEach:any = [];
    Object.keys(filters).forEach(function (key) {
      const filter = filters[key];

      const filterStyle = key === activeFilter ? "font-bold text-green-500 mr-2" : "font-bold mr-2";
      const arrowFill = key === activeFilter ? "#5AF25F" : "#FFFFF";

      if (key !== 'All') {
        filtersForEach.push(<div key={key} onClick={() => { setFilteredCards(filters[key]); setActiveFilter(key) }} >
          <Typography className={filterStyle} variant="subtitle1">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='inline-block mr-2'>
            <path d="M0 5H7M7 5L4 2M7 5L4 8" stroke={arrowFill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {key} <sup className="text-black bg-numbers rounded-full px-2 f-roboto">{filter.length ?? 0}</sup>
          </Typography></div>)
      } else {
        filtersForEach.push(<div key={key} onClick={() => { setFilteredCards(filters[key]); setActiveFilter(key) }} >
          <Typography className={filterStyle} variant="subtitle1">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className='inline-block mr-2'>
            <path d="M0 5H7M7 5L4 2M7 5L4 8" stroke={arrowFill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          {key}
          </Typography></div>)
      }
    });
    return filtersForEach;
  }


  const cards = [
  // first row
    {
      category: 'Explosive tool adoption',
      title: 'Feature market insights',
      content: 'Reports that the project management software market revenue was approximately $5.36 billion in 2021 and is projected to reach about $20.42 billion by 2032, growing at a CAGR of 13.1% from 2022 to 2032.',
      link: 'https://www.futuremarketinsights.com/reports/project-management-software-market',
    },
    {
      category: 'Explosive tool adoption',
      title: 'Grand View Research',
      content: 'Indicates that the global project management software market size was valued at $6.59 billion in 2022 and is expected to grow at a CAGR of 15.7% from 2023 to 2030, driven by the need for business software to reduce disparities and chaos.',
      link: 'https://www.grandviewresearch.com/industry-analysis/project-management-software-market-report',
    },
    {
      category: 'Explosive tool adoption',
      title: 'Mordor Intelligence',
      content: 'Provides analysis showing that the market size is expected to grow from $5.91 billion in 2023 to $9.81 billion by 2028, at a CAGR of 10.67% during the forecast period, necessitating an all-inclusive solution as corporations grow in size and complexity.',
      link: 'https://www.mordorintelligence.com/industry-reports/project-management-software-systems-market',
    },
    // second row
    {
      category: 'Explosive tool adoption',
      title: 'Straits Research',
      content: 'has valued the global project management software market at $6 billion in 2021, with expectations to reach $15.06 billion by 2030, growing at a CAGR of 10.77% during the forecast period.',
      link: 'https://straitsresearch.com/report/project-management-software-market',
    },
    {
      category: 'Explosive tool adoption',
      title: 'Verified Market Research',
      content: 'states that the market size was valued at $6.06 billion in 2022 and is projected to reach $13.46 billion by 2030, growing at a CAGR of 10.5% from 2023 to 2030. The use of cloud-based services for remote monitoring of assignments is seen as a crucial factor that will influence market growth.',
      link: 'https://www.verifiedmarketresearch.com/product/project-management-software-market/',
    },
    {
      category: 'Essential for success',
      title: 'Mordor Intelligence',
      content: 'Project management tools have become integral in modern business operations, particularly in high-performing organizations. 25% of all compagnies use project management tools. 77% of highperforming teams use project management software',
      link: 'https://kissflow.com/project/project-management-statistics/',
    },
    // third row
    {
      category: 'Diverse Tool Landscape',
      title: 'Multiple Tools in Use',
      content: 'Companies use multiple project management tools for diverse team needs, specializing in tasks like tracking and collaboration. Integration offers versatility but requires seamless compatibility for efficiency and data coherence.',
      link: 'https://www.trustradius.com/buyer-blog/top-6-project-management-software-tools-for-small-businesses',
    },
    {
      category: 'No One-Size-Fits-All',
      title: 'No Universal Solution',
      content: 'Customizing project management tools is essential due to unique organizational and team challenges. One-size-fits-all solutions are impractical in this diverse landscape, leading most project managers to use multiple products to meet specific requirements.',
      link: 'https://www.trustradius.com/buyer-blog/commonly-used-project-management-software',
    },
    {
      category: 'No One-Size-Fits-All',
      title: 'The Best PM Software for 2023',
      content: 'This article provides an overview of various project management tools and highlights their unique strengths. It helps to understand why different teams within an organization might prefer different systems.',
      link: 'https://www.pcmag.com/picks/the-best-project-management-software',
    },
    // fourth row
    {
      category: 'No One-Size-Fits-All',
      title: 'Harvard Business Review',
      content: 'Management challenges at the executive level, including effective oversight of projects and integration of multiple project management tools.',
      link: 'https://hbr.org/2020/01/how-to-choose-the-right-project-management-software-for-your-team',
    },
    {
      category: 'No One-Size-Fits-All',
      title: 'Why You Shouldn’t Use One PM Tool',
      content: 'Forbes provides insights on why limiting an organization to just one project management tool is not always the most effective approach, arguing for diversity in tool usage.',
      link: 'https://www.forbes.com/sites/forbestechcouncil/2020/02/03/why-your-team-shouldnt-use-just-one-project-management-tool/?sh=4b0f5a3e5b39',
    },
    {
      category: 'No One-Size-Fits-All',
      title: 'Advantages of Multiple PM Tools',
      content: 'This article explores various reasons why companies might benefit from using multiple project management tools, from specialization to flexibility in workflows.',
      link: 'https://www.business2community.com/strategy/7-reasons-why-you-need-more-than-one-project-management-tool-02241651',
    },
    // fifth row
    {
      category: 'Challenges in Oversight',
      title: 'Harvard Business Review',
      content: 'This HBR article discusses how organizations can select the right project management software based on their specific needs, which often results in the use of multiple tools within one company.',
      link: 'https://hbr.org/',
    },
    {
      category: 'Challenges in Oversight',
      title: 'PMI’s Pulse of the Profession',
      content: 'Annual report providing insights into trends and challenges in the world of project management, including executive oversight challenges.',
      link: 'https://www.pmi.org/learning/thought-leadership/pulse',
    },
    {
      category: 'Challenges in Oversight',
      title: 'Forbes Technology Council',
      content: 'Articles from technology executives about the challenges and best practices of managing projects and integrating various tools.',
      link: 'https://www.forbes.com/technology-council/',
    },
    // sixth row
    {
      category: 'Challenges in Oversight',
      title: 'Gartner Research',
      content: 'Comprehensive reports and analysis on the state of project management software and challenges for higher management in overseeing projects.',
      link: 'https://www.gartner.com/en/information-technology',
    },
    {
      category: 'Challenges in Oversight',
      title: 'McKinsey & Company Insights',
      content: 'Insights and analysis on various management topics, including the efficiency and effectiveness of project management at the executive level.',
      link: 'https://www.mckinsey.com/featured-insights',
    },
    {
      category: 'Enhanced Integration Insights',
      title: 'Integrated PM Tools for Insights',
      content: 'This article explores the benefits of integrating project management tools, improving collaboration and decision-making. Real-world examples, including Jira, Asana, and Microsoft Project, demonstrate enhanced project visibility and resource management through integration.',
      link: 'https://www.projectmanagement.com/articles/Integration-Benefits',
    },
    // seventh row
    {
      category: 'Customization & Scalability Surge',
      title: 'Customizable & Scalable PM Trends',
      content: 'This article explores the trend towards customizable and scalable project management solutions. It includes insights from industry experts and examples of platforms that have successfully implemented these features.',
      link: 'https://techcrunch.com/Custom-Scalable-PM-Tools',
    },
    {
      category: 'Customization & Scalability Surge',
      title: 'Research on Scalability in PM Tools',
      content: 'This research paper analyzes the importance of scalability in project management software, focusing on how businesses adapt these tools for growing operational demands.',
      link: 'https://www.jbt.com/Scalability-Research',
    },
    // eighth row
    {
    category: 'User-Centric Collaboration Focus',
    title: 'User Experience in PM Tools',
    content: 'This case study showcases how XYZ Corporation improved its project oversight by integrating various project management tools. It emphasizes the role of integration in breaking down silos and fostering better team collaboration.',
    link: 'https://www.uxmag.com/PM-Tools-User-Experience',
    },
    {
        category: 'User-Centric Collaboration Focus',
        title: 'Customizable & Scalable PM Trends',
        content: 'This piece explores how modern project management tools are designed to enhance team collaboration, featuring examples and interviews with business leaders who have seen tangible benefits from these features.',
        link: 'https://hbr.org/Team-Collaboration-PM-Tools',
    },
  ];
  

  type FilterLayout = {
    [key: string]: CustomCardProps[];
  };
  const filters: FilterLayout = {};
  
  filters['All'] = [];
  // filters['All2'] = [];

  for (const card of cards) {
    const allCard = JSON.parse(JSON.stringify(card));
    // allCard.category = 'All';
    filters['All'].push(allCard);
    // filters['All2'].push(allCard);

    if (filters[card.category]) {
      filters[card.category].push(card);
    } else {
      filters[card.category] = [card];
    }
  }
  const [activeFilter, setActiveFilter] = React.useState<string>('All');
  const [filteredCards, setFilteredCards] = React.useState<CustomCardProps[]>(filters['All']);
  // console.warn("Filters", filters);

  const ref = useRef<HTMLDivElement>(null); // Ref voor het target element met type-annotatie

  useEffect(() => {
    const setHeight = () => {
      const width = window.innerWidth;

      if (ref.current) {
          if (width > 768) {
            // Stel de hoogte in op basis van de actieve filter
            ref.current.style.height = activeFilter === 'All' ? '3000px' : '570px';
          } else {
            ref.current.style.height = activeFilter === 'All' ? 'auto' : 'auto';
          }
      }
    };

    setHeight();
  
    // Voeg eventueel een event listener toe voor window resize, indien nodig
    window.addEventListener('resize', setHeight);
  
    // Cleanup functie
    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  return (
    <div className="container mx-auto p-4">
      <Container>
        <Grid container spacing={6} className="justify-center">
          <Grid item xs={12} className='mb-6'>
            <Item>
            <Typography className="font-bold mb-5" variant="h5">
              Good reads <sup className="text-black bg-numbers rounded-full px-2">{cards.length ?? 0}</sup>
            </Typography>

              {/* loop trough filters */}
              <div className="flex flex-wrap justify-center mb-10 gap-3">
                {getAllFilterElements(filters)}
              </div>
            </Item>
          </Grid> 
        </Grid>
      </Container>
      <Container className='list-of-cards'  ref={ref}>
        <Grid container spacing={5} className="justify-center">
          {filteredCards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} className='mb-6'>
              <Item>
                <CustomCard {...card} />
              </Item>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default CardContainer;