import Typography from "@mui/material/Typography";
import React from "react";


import Hero from "../../components/elements/Hero";
import { Container } from "@mui/material";

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import TimelineStructure from "../../components/elements/Timeline/TimelineStructure";

// import TeamCard from "./elements/TeamCard";

import HeroModal from "../../components/elements/Modals/HeroModal";

import useMeta from "../../components/useMeta";

type Props = any;

const Roadmap = (props: Props) => {
  return (
    useMeta({
      title: 'ActOnIQ',
      description: '/',
      keywords: '',
    }),
    <>
      <div className="">
        <TimelineStructure />
      </div>
    </>
  )
};

export default Roadmap;