import { AppBar, Grid, List, Stack } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import React from "react";
import ScrollToAnchor from "../../hooks/ScrollToAnchor";
import TopbarItem from "./TopbarItem";
import Routes from "../../routes/routes";

import { Container } from "@mui/material";

import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

type Props = any;

const Topbar = (props: Props) => {
  // create a a click toggle func for mobile-menu-trigger
  const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
    const mobileMenu = document.getElementById('mobile-menu') as HTMLElement;
    const mobileMenuIcon = document.getElementById('mobile-menu-trigger-menu') as HTMLElement;
    const mobileMenuCloseIcon = document.getElementById('mobile-menu-trigger-close') as HTMLElement;
    //Get all .mobile-menu-trigger elements
    

    // Check if menu is already open
    if (mobileMenu.classList.contains('block')) {
      mobileMenuIcon.classList.remove('hidden');
      mobileMenuCloseIcon.classList.add('hidden');

      mobileMenu.classList.remove('slideDown');
      mobileMenu.classList.add('slideUp');
      
      
      
      //set timeout to allow animation to finish
      setTimeout(() => {
        mobileMenu.classList.remove('block');
        mobileMenu.classList.add('hidden');
      }, 200);

    }
    else {
      mobileMenuIcon.classList.add('hidden');
      mobileMenuCloseIcon.classList.remove('hidden');

      mobileMenu.classList.remove('slideUp');
      mobileMenu.classList.add('slideDown');

      setTimeout(() => {
        mobileMenu.classList.remove('hidden');
        mobileMenu.classList.add('block');
      }, 200);
    }

  };

  
  // const toggleMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  //   //prevent slide down of hideonscroll
  //   event.stopPropagation();
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  ScrollToAnchor();

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        zIndex: 1000,
      }}
    >
      <Toolbar className=" w-full">
        <Container className="bg-gradient-to-r from-primary-gray-950 to-60% to-white rounded-full mt-6 p-0">
          <Grid container direction="row" className="h-full" justifyContent="flex-start" columnSpacing={0} mt={0} height={60}>
              <Grid item container xs={2} className="">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleMobileMenu}
                  className="mobile-menu-trigger absolute top-7 md:top-8 w-16 md:right-5 right-3 z-10 mx-auto visible lg:invisible"
                >
                  <CloseIcon id="mobile-menu-trigger-close" className="hidden mobile-menu-trigger" />
                  <MenuIcon id="mobile-menu-trigger-menu" className="mobile-menu-trigger" />
                  
                </IconButton>
                
                <Link to="/" className="flex items-center h-15">
                  <img className="h-15" src="../../images/O.png" alt="logo-banner" loading='lazy' />
                </Link>
            </Grid>
            
              <Grid item container xs={10} justifyContent="flex-end" style={{ height: "0" }}>
                <Grid flexGrow={1}>
                  <List component={Stack} direction="row" justifyContent="flex-end">
                      {Routes.map((item, index) => (
                        item.showInMenu && item.showInMenu === true ?  (
                          item.displayText && !item.index ?  (
                            (
                              <div key={index} className="invisible lg:visible" >
                                <TopbarItem path={item.path} displayText={item.displayText} icon="" isExternal={false} />
                              </div>
                            )
                            ) : null
                          ) : null
                      ))}
                  <Button className="invisible lg:visible" variant="contained"
                    sx={{
                      backgroundColor: '#5932EA',
                      color: '#fff',
                      px: '12px',
                      py: '4px',
                      borderRadius: '50px',
                      '&:hover': {
                        backgroundColor: '#5932EA',
                      },
                      marginRight: '10px',
                    }}
                  >
                    <a href="/contact" style={{ textDecoration: 'none', color: 'inherit', fontSize: '1rem', fontWeight: 'bold'  }}>
                      Contact
                    </a>
                  </Button>

                      
                  </List>
                </Grid>
              </Grid>
          </Grid>

          

            <div id="left-menu-container" />
        </Container>
      </Toolbar>
      <div id="mobile-menu" className="bg-gradient-to-br from-primary-gray-300  to-60% to-white w-64 md:w-80 mx-auto hidden rounded-2xl p-4 z-20 relative text-center">
        <div>
                {Routes.map((item, index) => (
                  item.showInMenu && item.showInMenu === true ?  (
                    item.displayText && !item.index ?  (
                      (
                        <div key={index} onClick={toggleMobileMenu}>
                          <TopbarItem path={item.path}  icon="" displayText={item.displayText} isExternal={false}/>
                        </div>
                      )
                    ) : null
                  ) : null
                ))}
            <div>
                <Button
                  className="w-full"
                    variant="contained"
                    sx={{
                      backgroundColor: '#5932EA',
                      color: '#fff',
                      px: '12px',
                      py: '10px',
                      borderRadius: '50px',
                      '&:hover': {
                        backgroundColor: '#5932EA',
                      },
                      marginRight: '10px',
                    }}
            >
              <a href="/contact" style={{ textDecoration: 'none', color: 'inherit', fontSize: '1rem', fontWeight: 'bold' }}>

                Contact
              </a>
                </Button>
            </div>
              </div>
            </div>
    </AppBar>
  )
};

export default Topbar;